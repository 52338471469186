<template>
    <div>
        <!-- About Banner -->
        <div class="mx-2">
            <section class="about-banner">
                <div class="container">
                    <div class="title">
                        <h4>
                            {{ $t('About.title') }}
                            <span>{{ $t('About.spanTitle') }}?</span>
                        </h4>
                        <p>
                            {{ $t('About.subtitle') }}
                        </p>
                    </div>
                </div>
            </section>
        </div>

        <!-- About Banner End -->

        <!-- About Banner Img -->
        <section class="about-banner-img">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <picture>
                            <img
                                src="@/assets/mainImages/dashboard.png"
                                alt=""
                            />
                        </picture>
                    </div>
                </div>
            </div>
        </section>
        <!-- About Banner Img End -->

        <!-- About Section 01 -->
        <section class="about-section-01">
            <div class="container">
                <div class="title">
                    <h4>
                        {{ $t('About.section1.title.firstText') }}
                        <span> {{ $t('About.section1.title.span') }}</span>
                        {{ $t('About.section1.title.secondText') }}
                    </h4>
                    <p>
                        {{ $t('About.section1.subtitle') }}
                    </p>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="item">
                            <div class="icon first">
                                <img
                                    src="@/assets/mainImages/icons/a01.svg"
                                    alt=""
                                />
                            </div>
                            <div class="text">
                                <h4>
                                    {{ $t('About.section1.features1.title') }}
                                </h4>
                                <p>
                                    {{
                                        $t('About.section1.features1.subtitle')
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="item">
                            <div class="icon second">
                                <img
                                    src="@/assets/mainImages/icons/a02.svg"
                                    alt=""
                                />
                            </div>
                            <div class="text">
                                <h4>
                                    {{ $t('About.section1.features2.title') }}
                                </h4>
                                <p>
                                    {{
                                        $t('About.section1.features2.subtitle')
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="item">
                            <div class="icon third">
                                <img
                                    src="@/assets/mainImages/icons/a03.svg"
                                    alt=""
                                />
                            </div>
                            <div class="text">
                                <h4>
                                    {{ $t('About.section1.features3.title') }}
                                </h4>
                                <p>
                                    {{
                                        $t('About.section1.features3.subtitle')
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- About Section 01 End -->

        <!-- Home Banner 02 -->
        <section class="home-banner-02">
            <div class="container">
                <div class="text">
                    <p>
                        {{ $t('Home.homeBanner02.paragraph1') }}
                        <span> {{ $t('Home.homeBanner02.span1') }}</span>
                        {{ $t('Home.homeBanner02.paragraph2') }}
                        <span>
                            {{ $t('Home.homeBanner02.span2') }}
                        </span>
                        {{ $t('Home.homeBanner02.paragraph3') }}
                    </p>
                    <a
                        href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fmarketplace%2Fapp-details%2F51We0QQmNTAE"
                        class="button"
                    >
                        {{ $t('Home.homeBanner02.action') }}
                        <img src="@/assets/mainImages/icons/arrow-right.svg" />
                    </a>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/firstlayout/about.scss';
</style>
